var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"grey lighten-5",attrs:{"id":"features"}},[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"display-1 mb-3 primary--text text-uppercase"},[_c('span',{staticClass:"main-title"},[_vm._v("interactive visualizations")])]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-data-iterator',{attrs:{"items":_vm.visualizations,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"color":"grey lighten-5","max-width":"600"}},[_c('v-img',{attrs:{"aspect-ratio":16 / 9,"src":item.src}},[_c('v-expand-transition',[(hover)?_c('div',{class:[
                        'px-2',
                        'd-flex',
                        'transition-fast-in-fast-out',
                        ' font-weight-bold',
                        'cyan darken-2',
                        'v-card--reveal',
                        'white--text',
                        _vm.$vuetify.breakpoint.lgAndDown ? 'title' : 'headline'
                      ],staticStyle:{"height":"100%"}},[_c('span',{staticClass:"visualization-description"},[_vm._v(_vm._s(item.text))])]):_vm._e()])],1),_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('visualization-dialog',{attrs:{"visualizationLink":item.link,"visualizationTitle":item.title}})],1)],1)]}}],null,true)})],1)}),1)]}}])}),_c('div',{staticClass:"mt-5"},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","fab":"","text":"","outlined":"","color":"info"},on:{"click":_vm.formerPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","small":"","text":"","outlined":"","color":"info"},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1),_c('div',{staticClass:"py-12"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }